.clearButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

.searchButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.searchButton, .clearButton {
  height: 32px;
  width: 44px;
  min-width: 44px;
  padding: 0;
}

.searchButton span, .clearButton span {
  width: 44px;
  min-width: 44px;
}

.searchResult {
  background-color: #D0D0FF;
}

.searchText {
}

.searchText .MuiInputBase-root {
  border-radius: 0;
}

.searchText .MuiInputBase-input {
  padding: 0 10px;
  height: 32px;
  width: 260px;
  color: rgba(0, 0, 0, 0.7)
}
